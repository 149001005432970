<template>
  <div class="choicePaySettle" id="choicePaySettle">
    <nav-bar title="在线支付" :hasLeftClick="true" :clickLeft="clickLeft">
      <span slot="right"></span>
    </nav-bar>
    <div v-if="!queryIng">
      <div class="content">
        <div class="title">应付金额</div>
        <div class="price">¥{{ epcSearchPackageObj.packagePrice }}</div>
        <div class="orderNo">{{ epcSearchPackageObj.packageName }}</div>
        <div class="footer">{{ epcSearchPackageObj.packageDesc }}</div>
      </div>
      <div class="paySettle">
        <div class="choice">选择支付方式</div>
        <div class="settleList">
          <van-radio-group v-model="settleType">
            <van-cell-group>
              <!-- 由于量子支付有问题，暂时隐藏在线支付 -->
              <van-cell
                clickable
                @click="settleType = '100704'"
                v-show="payTypeObj.payType == '100704'"
              >
                <template #title>
                  <div class="titleWrapper">
                    <img src="@/assets/onlinePay.jpg" alt="" />
                    在线支付
                  </div>
                </template>
                <template #right-icon>
                  <van-radio name="100704" checked-color="#FF7E3F" />
                </template>
              </van-cell>
              <!-- v-if="terminalType == 'MSITE' && !isAndroid " 隐藏微信支付代码 -->
              <van-cell
                clickable
                @click="settleType = '100709'"
                v-show="payTypeObj.payType == '100709'"
              >
                <!-- v-if="terminalType == 'MSITE'" -->
                <template #title>
                  <div class="titleWrapper">
                    <img src="@/assets/wechatPay.png" alt="" />
                    微信支付
                  </div>
                </template>
                <template #right-icon>
                  <van-radio name="100709" checked-color="#FF7E3F" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </div>
      <div class="fastPay btnSign" @click="getPayUrl">立即支付</div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
import { Cell, CellGroup, RadioGroup, Radio, Toast } from "vant";
import { getParams, is_wexin } from "@/utils/util";
import NavBar from "@/components/NavBar.vue";
import {
  getPayUrl,
  addBuyEsPackage,
  getPayResult,
  epcPackagePaymentPay,
} from "@/api";
export default {
  name: "choicePaySettle",
  components: {
    NavBar,
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  data() {
    return {
      settleType: "100709",
      epcPackagePaymentId: "", //订单id
      epcSearchPackageObj: {}, //订单信息
      payTypeObj: {}, //支付方式
      terminalType: "", //支付请求来源渠道
      queryIng: false, //此时是否在查询中
      userId: "",
      partyId: "",
      payStatus: 0,
    };
  },
  methods: {
    clickLeft() {
      if(this.payStatus){
        this.$router.push({name: 'buyCombe'})
      }else{
        this.$router.go(-1);
      }
    },
    //确认支付接口
    getPayUrl() {
      if (!this.settleType) {
        Toast.fail("请先选择支付方式");
        return;
      }
      Toast.loading({
        message: "支付跳转中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
      });
      var backParams = JSON.stringify({
        fromUrl : sessionStorage.getItem('fromUrl'),
        source : sessionStorage.getItem('source'),
        partyId : sessionStorage.getItem('partyId')
      })
      var data = {
        userId: this.userId,
        epcPackagePaymentId: this.epcPackagePaymentId,
        payType: this.settleType,
        terminalType: this.terminalType,
        clientBrowser: is_wexin() && 'wechatBrowser' || 'otherBrowser',
        backParams
      };
      getPayUrl(data)
        .then((res) => {
          console.log(res);
          var { pageRetUrl, paySerNo, payUrl } = res.data.data;
          window.location.href = payUrl;
          setTimeout(() => {
            Toast.clear();
          }, 1000);
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    //生成订单
    toBuyCombe(epcSearchPackageId) {
      addBuyEsPackage({
        epcSearchPackageId,
        settleType: 2,
        userId: this.userId,
        partyId: sessionStorage.getItem("partyId"),
      }).then((res) => {
        console.log(res);
        if (res && res.data.status.code == 0) {
          console.log(res.data.data);
          var {
            epcPackagePaymentId,
            epcSearchPackageObj,
            payTypeObj,
          } = res.data.data;
          this.epcPackagePaymentId = epcPackagePaymentId;
          this.epcSearchPackageObj = epcSearchPackageObj;
          this.payTypeObj = payTypeObj;
        }
      });
    },
    //获取支付渠道是否为PC
    getTermial() {
      function IsPC() {
        var userAgentInfo = navigator.userAgent;
        var Agents = [
          "Android",
          "iPhone",
          "SymbianOS",
          "Windows Phone",
          "iPad",
          "iPod",
        ];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
          if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
          }
        }
        return flag;
      }
      if (IsPC()) {
        this.terminalType = "PC";
      } else {
        this.terminalType = "MSITE";
      }
    },
    //查询订单是否支付成功
    getStatus() {
      console.log(window.location.href)
      getPayResult({ ...getParams(), userId: this.userId || getParams().userId })
        .then((res) => {
          console.log(res);
          if (res && res.data.status.code == 0) {
            this.result = { ...res.data.data };
            var {fromUrl,source,partyId} = this.result.backParams;
            sessionStorage.setItem('fromUrl', window.decodeURIComponent(fromUrl));
            sessionStorage.setItem('source',source);
            sessionStorage.setItem('partyId',partyId);
            if (this.result.resCode == "PAYING") {
              setTimeout(() => {
                this.getStatus();
              }, 2000);
            } else if (this.result.resCode == "OK") {
              Toast.success("支付成功！");
              setTimeout(() => {
                this.$router.push("buyRecord");
              }, 1000);
              return;
            } else {
              epcPackagePaymentPay({
                userId: this.userId,
                epcPackagePaymentId: this.result.epcPackagePaymentId,
              }).then((res) => {
                var {
                  epcPackagePaymentId,
                  epcSearchPackageObj,
                  payTypeObj,
                } = res.data.data;
                this.epcPackagePaymentId = epcPackagePaymentId;
                this.epcSearchPackageObj = epcSearchPackageObj;
                this.payTypeObj = payTypeObj;
                this.queryIng = false;
                Toast.fail("支付失败！");
                
              });
              return;
            }
            Toast.clear();
          } else {
            Toast.clear();
          }
        })
        .catch((err) => {
          Toast.clear();
        });
    },
  },
  mounted() {
    this.userId = sessionStorage.getItem("userId");
    this.partyId = sessionStorage.getItem("partyId");
    this.getTermial();
    if (this.$route.params.id) {
      this.payStatus = 0;
      this.toBuyCombe(this.$route.params.id);
    } else if (getParams().paySerNo) {
      this.payStatus = 1;
      this.queryIng = true;
      this.userId = getParams().userId;
      sessionStorage.setItem('userId',this.userId)
      Toast.loading({
        message: "支付结果查询中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      setTimeout(() => {
        this.getStatus();
      }, 2000);
    };
  },
};
</script>

<style lang="less" scoped>
.class_qidian_wpa {
  visibility: hidden;
}
.choicePaySettle {
  padding-top: 50px;
  background: #f8f8f8;
  height: 100%;
  .fastPay {
    cursor: pointer;
    height: 47px;
    line-height: 47px;
    background: linear-gradient(to right, #1677ff, #2f99ff);
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 16px;
  }
  .settleList {
    margin: 0 -14px;
    .van-cell__title {
      .titleWrapper {
        display: flex;
        align-items: center;
        img {
          width: 22px;
          height: 22px;
          margin-right: 5px;
        }
        font-size: 15px;
        color: #464646;
      }
    }
  }
  .paySettle {
    padding: 0 30px;
    background: #fff;
    border-top: 1px solid transparent;
    .choice {
      height: 60px;
      line-height: 60px;
      color: #161616;
      font-size: 17px;
      font-weight: bold;
    }
  }
  .van-nav-bar .van-icon {
    font-size: 22px;
    color: #2e2e2e !important;
  }
  .content {
    margin: 5px 0 8px 0;
    background: #fff;
    text-align: center;
    border-top: 1px solid transparent;
    .title {
      font-weight: bold;
      margin: 25px 0 15px 0;
      color: #161616;
      font-size: 16px;
      margin-bottom: 10px;
    }
    .price {
      color: #ff7e3f;
      font-size: 20px;
      font-weight: bold;
    }
    .orderNo {
      color: #262d3d;
      font-size: 13px;
      margin: 8px 0 22px 0;
    }
    .footer {
      height: 60px;
      border-top: 1px solid #eeeeee;
      color: #b2b2b2;
      padding: 10px 15px;
      text-align: left;
    }
  }
}
</style>